export const KO = "KO";
export const EN = "EN";
export const JP = "JP";

export const langKo = (count) => {
  return {
    type: KO,
    payload: "ko",
  };
};

export const langEn = (count) => {
  return {
    type: EN,
    payload: "en",
  };
};

export const langJp = (count) => {
  return {
    type: JP,
    payload: "jp",
  };
};
