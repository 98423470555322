import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Visuallogo from "./images/visual_logo.png";

function Section01() {
  const { t } = useTranslation();
  const isTablet = useMediaQuery({ query: "(min-width: 575px)" });
  return (
    <>
      {isTablet ? (
        <section id="visual">
          <div class="section-wrapper">
            <div class="section-img-wrapper">
              <img src={Visuallogo} alt="visuallogo" />
            </div>
            <div class="section-txt-wrapper">
              <div className="txt-title">{t("section01_title")}</div>
              <div class="section-txt-wrapper2">
                <div className="txt-sub">{t("section01_sub1")}</div>
                <div class="divider"></div>
                <div className="txt-sub">{t("section01_sub2")}</div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section id="m_visual">
          <div class="section-wrapper" id="top">
            <div class="section-img-wrapper">
              <img src={Visuallogo} alt="m_logo" />
            </div>
            <div class="section-txt-wrapper">
              <div className="txt-title">{t("section01_title")}</div>
              <div class="section-txt-wrapper2">
                <div className="txt-sub">{t("section01_sub1")}</div>
                <div class="divider"></div>
                <div className="txt-sub">{t("section01_sub2")}</div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Section01;
