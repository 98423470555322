import React from "react";
import { Link } from "react-scroll";

const SideNav = () => {
  return (
    <nav className="snb-nav">
      <div className="snb-wrapper">
        <div className="menu">
          <Link
            activeClass="active"
            className="item01"
            to="item01"
            spy={true}
            smooth={true}
            duration={500}
          >
            <h3>HOME</h3>
          </Link>
          <Link
            activeClass="active"
            className="item02"
            to="item02"
            spy={true}
            smooth={true}
            duration={500}
          >
            <h3>ABOUT</h3>
          </Link>
          <Link
            activeClass="active"
            className="item03"
            to="item03"
            spy={true}
            smooth={true}
            duration={500}
          >
            <h3>PRODUCT</h3>
          </Link>
          <Link
            activeClass="active"
            className="item04"
            to="item04"
            spy={true}
            smooth={true}
            duration={500}
          >
            <h3>C. TV</h3>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default SideNav;
