import React, { useState, useEffect } from "react";
import { ModalProvider } from "react-simple-hook-modal";
// import { useTranslation } from "react-i18next";
import logoTop from "./images/lnb_logo.png";
// import lang from "./images/btn_lang.svg";
// import FortuneItemPop01 from "./FortuneItemPop01";
import LanguagePop from "./LanguagePop";

const NavFullMenu = () => {
  const [navCondition, setNavCondition] = useState("top");
  const [count, setCount] = useState(0);

  // const { t } = useTranslation();

  useEffect(() => {
    document.addEventListener("scroll", (e) => {
      let scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 5) {
        setNavCondition("moved");
      } else {
        setNavCondition("top");
      }
    });
    return () => {
      console.log("clean");
    };
  }, []);

  useEffect(() => {
    if (count > 0) {
      setNavCondition("moved");
    }
    return () => {
      // Cleanup
      setCount("top");
    };
  }, [count]);
  return (
    <nav
      style={{
        backgroundColor:
          navCondition === "top" ? "rgba(0,0,0,0)" : "rgba(255,255,255,0.8)",
      }}
    >
      <div className="nav-wrapper">
        <div className="logo">
          <a href="http://celtage.com/">
            <img src={logoTop} alt="logo" />
          </a>
        </div>
        <div className="menu"></div>
        <ModalProvider
          backdropClassName="rsm-bg-black rsm-opacity-70"
          modalClassName="ttr"
        >
          <LanguagePop />
        </ModalProvider>
      </div>
    </nav>
  );
};

export default NavFullMenu;
