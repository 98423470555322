import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

function Section2() {
  const { t } = useTranslation();
  const isTablet = useMediaQuery({ query: "(min-width: 575px)" });
  return (
    <>
      {isTablet ? (
        <section id="about2">
          <div class="section-img-wrapper"></div>
          <div class="section-wrapper">
            <div class="txt-title">{t("section03_title")}</div>
            <div class="section-txt-wrapper">
              <div class="item">
                <div class="txt-sub">{t("section03_sub1")}</div>
                <div class="txt-text">{t("section03_text1")}</div>
              </div>
              <div class="item">
                <div class="txt-sub">{t("section03_sub2")}</div>
                <div class="txt-text">{t("section03_text2")}</div>
              </div>
              <div class="item">
                <div class="txt-sub">{t("section03_sub3")}</div>
                <div class="txt-text">{t("section03_text3")}</div>
              </div>
              <div class="item">
                <div class="txt-sub">{t("section03_sub4")}</div>
                <div class="txt-text">{t("section03_text4")}</div>
              </div>
            </div>
          </div>
          <div id="line"></div>
        </section>
      ) : (
        <section id="m_about2">
          <div class="section-wrapper">
            <div class="txt-title">{t("m_section03_title")}</div>
            <div class="section-txt-wrapper">
              <div class="item">
                <div class="txt-sub">{t("m_section03_sub1")}</div>
                <div class="txt-text">{t("m_section03_text1")}</div>
              </div>
              <div class="item">
                <div class="txt-sub">{t("m_section03_sub2")}</div>
                <div class="txt-text">{t("m_section03_text2")}</div>
              </div>
              <div class="item">
                <div class="txt-sub">{t("m_section03_sub3")}</div>
                <div class="txt-text">{t("m_section03_text3")}</div>
              </div>
              <div class="item">
                <div class="txt-sub">{t("m_section03_sub4")}</div>
                <div class="txt-text">{t("m_section03_text4")}</div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Section2;
