import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

function Section06() {
  const { t } = useTranslation();
  const isTablet = useMediaQuery({ query: "(min-width: 575px)" });
  return (
    <>
      {isTablet ? (
        <section id="c_tv">
          <div class="section-wrapper">
            <div class="section-txt-wrapper">
              <div class="txt-title">{t("section06_title")}</div>
              <div class="txt-sub">{t("section06_sub")}</div>
              <a
                href="https://www.youtube.com/watch?v=xZXRcK46k74&list=TLGGdbKD0rHGEcYxNDAyMjAyMw&t=3s"
                target="blank"
                id="youtube"
              >
                <div>ss</div>
              </a>
            </div>
          </div>
        </section>
      ) : (
        <div id="m_c_tv">
          <ul>
            <li>
              <div class="txt-title">{t("section06_title")}</div>
            </li>
            <li>
              <div class="txt-sub">{t("section06_sub")}</div>
            </li>
          </ul>
          <a
            href="https://www.youtube.com/watch?v=xZXRcK46k74&list=TLGGdbKD0rHGEcYxNDAyMjAyMw&t=3s"
            target="blank"
          >
            <div></div>
          </a>
        </div>
      )}
    </>
  );
}

export default Section06;
