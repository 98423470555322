import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Person from "./images/person.png";
import m_person from "./images/m_person.png";

function Section05() {
  const { t } = useTranslation();
  const isTablet = useMediaQuery({ query: "(min-width: 575px)" });
  return (
    <>
      {isTablet ? (
        <section id="product2">
          <div class="section-wrapper">
            <div class="section-img-wrapper">
              <img src={Person} alt="person" />
            </div>
            <div class="section-txt-wrapper">
              <div class="txt-title">{t("section05_title")}</div>
              <div class="txt-sub">{t("section05_sub")}</div>
            </div>
          </div>
          <div id="model">
            <div class="txt-text">{t("section05_text")}</div>
          </div>
        </section>
      ) : (
        <div id="m_product2">
          <div class="section-wrapper">
            <div class="section-txt-wrapper">
              <div class="txt-title">{t("m_section05_title")}</div>
              <div class="txt-sub">{t("m_section05_sub")}</div>
            </div>
            <div class="section-img-wrapper">
              <img src={m_person} alt="m_person" />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Section05;
