import React, { useEffect } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import { useMediaQuery } from "react-responsive";
import NavFullMenu from "./NavFullMenu";
import "./style.scss";
import "./reset.scss";
import { Element } from "react-scroll";
import Section01 from "./Section01";
import Section02 from "./Section02";
import Section03 from "./Section03";
import Section04 from "./Section04";
import Section05 from "./Section05";
import Section06 from "./Section06";
import Footer from "./Footer";
import SideNav from "./SideNav";

const App = () => {
  const isTablet = useMediaQuery({ query: "(min-width: 575px)" });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <ParallaxProvider>
      <SideNav />
      {isTablet ? <NavFullMenu /> : <NavFullMenu />}
      <Element name="item01" className="element">
        <Section01 />
      </Element>
      <Element name="item02" className="element1">
        <Section02 />
        <Section03 />
      </Element>
      <Element name="item03" className="element2">
        <Section04 />
        <Section05 />
      </Element>
      <Element name="item04" className="element3">
        <Section06 />
      </Element>
      <Footer />
    </ParallaxProvider>
  );
};
export default App;
