import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationKo from "./language/translation.ko.json";
import translationEn from "./language/translation.en.json";
import translationJp from "./language/translation.jp.json";
// import translationIm from "./language/translation.im.json";
// import logoTop from "./images/logo_top.svg";
const resources = {
  en: {
    translation: translationEn,
  },
  ko: {
    translation: translationKo,
  },
  jp: {
    translation: translationJp,
  },
};
i18n.use(initReactI18next).init({
  resources,
  lng: "ko",
});
export default i18n;
