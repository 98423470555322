import { KO, EN, JP } from "../actions";
import { combineReducers } from "redux";

const initState = {
  value: "ko",
};

const change = (state = initState, action) => {
  switch (action.type) {
    case KO:
      return { ...state, value: action.payload };
    case EN:
      return { ...state, value: action.payload };
    case JP:
      return { ...state, value: action.payload };
    default:
      return state;
  }
};

const rootReducer = combineReducers({ change });

export default rootReducer;
