import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as actions from "./actions";
import { Modal, useModal, ModalTransition } from "react-simple-hook-modal";
import "react-simple-hook-modal/dist/styles.css";
import BtnClose from "./images/btn_close.svg";
import lang from "./images/language.png";

const LangaugePop = () => {
  const { t } = useTranslation();
  const { isModalOpen, openModal, closeModal } = useModal();
  const dispatch = useDispatch();
  const change = useSelector((store) => store.change);
  const { i18n } = useTranslation();
  function langKo(e) {
    e.preventDefault();
    i18n.changeLanguage("ko");
    dispatch(actions.langKo(change.value));
    closeModal();
  }
  function langEn(e) {
    e.preventDefault();
    i18n.changeLanguage("en");
    dispatch(actions.langEn(change.value));
    closeModal();
  }
  function langJp(e) {
    e.preventDefault();
    i18n.changeLanguage("jp");
    dispatch(actions.langJp(change.value));
    closeModal();
  }

  return (
    <>
      <div className="lang oepn-item" onClick={openModal}>
        <img src={lang} alt="" />
        <div className="lang_txt">{t("language_menu")}</div>
      </div>
      <Modal
        id="any-unique-identifier"
        isOpen={isModalOpen}
        transition={ModalTransition.NONE}
      >
        <div className="top-wrapper">
          <button className="modal-btn_close" onClick={closeModal}>
            <img src={BtnClose} alt="close button" />
          </button>
          <div className="modal-title">
            <h2>{t("languagepop_title")}</h2>
          </div>
        </div>
        <div className="modal-contents">
          <div className="contents-txt">
            <h4>
              {t("languagepop_txt1")} <br />
              {t("languagepop_txt2")}
            </h4>
          </div>

          <div className="language-list">
            <button onClick={langKo}>
              {change.value === "ko" ? (
                <p className="slected">한국어</p>
              ) : change.value === "en" ? (
                <p>Korean</p>
              ) : change.value === "jp" ? (
                <p>韓国語</p>
              ) : (
                false
              )}
            </button>
            <button onClick={langEn}>
              {change.value === "ko" ? (
                <p>영어</p>
              ) : change.value === "en" ? (
                <p className="slected">English</p>
              ) : change.value === "jp" ? (
                <p>英語</p>
              ) : (
                false
              )}
            </button>
            <button onClick={langJp} className="last-btn">
              {change.value === "ko" ? (
                <p>일본어</p>
              ) : change.value === "en" ? (
                <p>Japanese</p>
              ) : change.value === "jp" ? (
                <p className="slected">日本語</p>
              ) : (
                false
              )}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LangaugePop;
