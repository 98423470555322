import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import device from "./images/device.jpg";
import cream from "./images/cream.jpg";
import ampule from "./images/ampule.jpg";
import arrow_btn from "./images/arrow_btn.png";

function Section04() {
  const { t } = useTranslation();
  const isTablet = useMediaQuery({ query: "(min-width: 575px)" });
  return (
    <>
      {isTablet ? (
        <section id="product1">
          <div class="section-wrapper">
            <ul>
              <li>
                <img src={device} alt="device" />
              </li>
              <li>
                <div class="txt-sub">{t("section04_sub1")}</div>
              </li>
              <li>
                <div class="txt-text">{t("section04_text1")}</div>
              </li>
              <li>
                <div class="btn">
                  <a href="https://siriint.com/product/%EC%85%80%ED%8B%B0%EC%A7%80-nano-s1/11/category/1/display/2/">
                    {t("btn")}
                    <img src={arrow_btn} alt="btn_arrow" />
                  </a>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <img src={ampule} alt="device" />
              </li>
              <li>
                <div class="txt-sub">{t("section04_sub2")}</div>
              </li>
              <li>
                <div class="txt-text">{t("section04_text2")}</div>
              </li>
              <li>
                <div class="btn">
                  <a href="https://siriint.com/product/%EC%85%80%ED%8B%B0%EC%A7%80-%EC%BD%94%EB%A5%B4%EC%85%8B-%EC%95%B0%ED%94%8C/10/category/1/display/2/">
                    {t("btn")}
                    <img src={arrow_btn} alt="btn_arrow" />
                  </a>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <img src={cream} alt="device" />
              </li>
              <li>
                <div class="txt-sub">{t("section04_sub3")}</div>
              </li>
              <li>
                <div class="txt-text">{t("section04_text3")}</div>
              </li>
              <li>
                <div class="btn">
                  <a href="https://siriint.com/product/%EC%85%80%ED%8B%B0%EC%A7%80-%EB%A7%A4%EC%A7%81-%EB%B3%BC%EB%A5%A8-%ED%81%AC%EB%A6%BC/12/category/1/display/2/">
                    {t("btn")}
                    <img src={arrow_btn} alt="btn_arrow" />
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </section>
      ) : (
        <div id="m_product1">
          <div class="section-wrapper">
            <ul>
              <li>
                <a href="https://siriint.com/product/%EC%85%80%ED%8B%B0%EC%A7%80-nano-s1/11/category/25/display/1/">
                  <img src={device} alt="m_device" />
                </a>
              </li>
              <li>
                <div class="txt-sub">{t("section04_sub1")}</div>
              </li>
              <li>
                <div class="txt-text">{t("section04_text1")}</div>
              </li>
            </ul>
            <ul>
              <li>
                <a href="https://siriint.com/product/%EC%85%80%ED%8B%B0%EC%A7%80-%EC%BD%94%EB%A5%B4%EC%85%8B-%EC%95%B0%ED%94%8C/10/category/25/display/1/">
                  <img src={ampule} alt="m_device" />
                </a>
              </li>
              <li>
                <div class="txt-sub">{t("section04_sub2")}</div>
              </li>
              <li>
                <div class="txt-text">{t("section04_text2")}</div>
              </li>
            </ul>
            <ul>
              <li>
                <a href="https://siriint.com/product/%EC%85%80%ED%8B%B0%EC%A7%80-%EB%A7%A4%EC%A7%81-%EB%B3%BC%EB%A5%A8-%ED%81%AC%EB%A6%BC/12/category/25/display/1/">
                  <img src={cream} alt="m_device" />
                </a>
              </li>
              <li>
                <div class="txt-sub">{t("section04_sub3")}</div>
              </li>
              <li>
                <div class="txt-text">{t("section04_text3")}</div>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default Section04;
