import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import About1_bg from "./images/about1.jpg";

function Section2() {
  const { t } = useTranslation();
  const isTablet = useMediaQuery({ query: "(min-width: 575px )" });
  return (
    <>
      {isTablet ? (
        <section id="about1">
          <div className="section-wrapper">
            <div className="section-img">
              <img src={About1_bg} alt="about1" />
            </div>
            <div className="section-txt-wrapper">
              <div className="txt-title">{t("section02_title")}</div>
              <div className="divider"></div>
              <div className="txt-sub">{t("section02_sub")}</div>
            </div>
          </div>
        </section>
      ) : (
        <section id="m_about1">
          <div class="section-wrapper">
            <div className="txt-title">{t("m_section02_title")}</div>
            <div classname="section-img-wrapper">
              <img src={About1_bg} alt="m_about" />
            </div>
            <div class="txt-sub">{t("m_section02_sub")}</div>
          </div>
        </section>
      )}
    </>
  );
}

export default Section2;
