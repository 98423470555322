import React from "react";
import { useTranslation } from "react-i18next";
//(this) import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Footerlogo from "./images/footer_logo.png";
import topbtn from "./images/topbtn.png";
// function personalInformation(e) {
//   e.preventDefault();
//   window.open = ("http://www.naver.com", "_blank");
// }
// function agreement(e) {
//   e.preventDefault();
//   window.open = "agreement.html";
// }

const Footer = () => {
  //(this) let d = new Date();
  //(this) let n = d.getFullYear();
  const { t } = useTranslation();
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" });
  //(this) const change = useSelector((store) => store.change);
  return (
    <>
      {isTablet ? (
        <article className="footer">
          <section id="footer1">
            <ul id="f_menu1">
              <li>
                <a href="https://www.youtube.com/@celtage475" target="blank">
                  Youtube
                </a>
              </li>
              <li>
                <a href="https://siriint.com/" target="blank">
                  Shop
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/celtage_official/"
                  target="blank"
                >
                  Instargram
                </a>
              </li>
            </ul>
          </section>

          <section class="footer2">
            <div class="section-wrapper">
              <div class="section-txt-wrapper">
                <div class="section-img-wrapper">
                  <img src={Footerlogo} alt="footerlogo" />
                </div>
                <div class="txt-text">{t("footer_text1")}</div>
                <div class="txt-sub">{t("footer_sub")}</div>
                <div class="txt-text">{t("footer_text2")}</div>
                <div class="txt-text">{t("footer_text3")}</div>
              </div>
            </div>
          </section>
        </article>
      ) : (
        <section id="m_footer">
          <div class="topbtn">
            <a href="#top">
              <img src={topbtn} alt="topbtn" />
            </a>
          </div>
          <div class="section-wrapper1">
            <div>
              <a href="https://www.youtube.com/@celtage475" target="blank">
                Youtube
              </a>
            </div>
            <div>
              <a href="https://siriint.com/" target="blank">
                Shop
              </a>
            </div>
            <div>
              <a
                href="https://www.instagram.com/celtage_official/"
                target="blank"
              >
                Instargram
              </a>
            </div>
          </div>
          <div class="section-wrapper2">
            <div class="section-txt-wrapper">
              <b>
                <div class="txt-text">{t("m_footer_text1")}</div>
              </b>
              <div class="txt-text">{t("m_footer_text2")}</div>
              <div class="txt-text">{t("m_footer_text3")}</div>
            </div>
            <div class="section-img-wrapper">
              <img src={Footerlogo} alt="m_f_logo" />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Footer;
